<template>
  <div>

    <div class="holder-comp">
      <div class="section section-join-letter">
        <h1 class="headline-keyword">Der Praxisgemeinschaft beitreten</h1>
        <p class="copytext-ambient">
          <span class="adress">Liebe Praxisraum Suchende,</span>
            Sie möchten einen gut ausgestatteten, fertig eingerichteten, großzügigen und stilvoll möblierten Praxisraum mieten, um sich sorgenfrei Ihren Patienten widmen zu können?<br>
            <br>
            Wir als Praxis Kollektiv teilen professionell kuratierten Therapie- und Ordinationraum.  Bei uns haben Sie die Möglichkeit die Sprechzimmer Graustein & Kronjuwel ab vier Stunden pro Woche für psychotherapeutische, psychiatrische oder beraterische Arbeit zu mieten. Sie können dadurch in Ruhe und Gelassenheit Ihre PatientInnen dort unterstützen, wo sie es am meisten brauchen: mit ihrer vollen Präsenz in bestem Ambiente und ohne sich mit Formalitäten belasten zu müssen. Nebenbei steht das Praxis Kollektiv Wollzeile für mehr Zusammenhalt und Gemeinschaft in den psychosozialen Berufen zur Förderung des professionellen Austauschs. Ich freue mich Sie kennenzulernen. 
        </p>
      </div>

      <div class="section section-join-details">
        <div class="information-list">
          <div class="information-list-item">
            <h5>Praxisraum Graustein</h5>
            <p>
              Ca. 30m2 groß. Ausgestattet mit zwei Ledersesseln, einem Tatami, einem Schreibtisch und einem optionalen Stuhl.
            </p>
          </div>

          <div class="information-list-item">
            <h5>Praxisraum Kronjuwel</h5>
            <p>
              Ca. 30m2 groß. Ausgestattet mit einer Couch aus Leder, zwei Ohrensesseln, einem Schreibtisch und einem optionalen Stuhl. 
            </p>
          </div>

          <div class="information-list-item">
            <h5>Wartezimmer & Kunst</h5>
            <p>
              Das Wartezimmer ist ein Ort zum Ankommen und Wohlfühlen. Unsere Praxisräume werden im Rahmen von kuratierten Ausstellungen mit Kunstwerken zeitgenössischer KünstlerInnen bespielt.
            </p>
          </div>

          <div class="information-list-item">
            <h5>Teeküche</h5>
            <p>
              …ist eine Ruhezone für Sie und bietet mit Kaffee- und Teevariationen einen Raum für Erholung zwischen den Therapiesitzungen. 
            </p>
          </div>

          <div class="information-list-item">
            <h5>Aufbewahrung</h5>
            <p>
              Ihre Dokumentationen, höchstpersönliche Arbeitsaufzeichnungen, Gesprächsnotizen oder Testunterlagen lassen sich sicher in verschließbaren Kästchen verstauen.
            </p>
          </div>

          <div class="information-list-item">
            <h5>Lage</h5>
            <p>
              Praxis Kollektiv Wollzeile
              <a href="https://www.google.com/maps/place/Wollzeile+31,+1010+Wien/@48.2083973,16.3758227,16z/data=!4m5!3m4!1s0x476d079fde75675b:0x79da62d060b23da6!8m2!3d48.208051!4d16.3779084" target="_blank" class="copytext-link" style="padding: 0;">Wollzeile 31/13,<br>1010 Wien</a></p>
          </div>

          <div class="information-list-item">
            <h5>Öffnungszeiten</h5>
            <p>Montag bis Freitag</p>
            <p>08:00 — 21:00 Uhr</p>
          </div>

          <div class="information-list-item">
            <h5>Barrierefreiheit</h5>
            <p>Barrierefreier Zugang mit Lift</p>
          </div>

          <div class="information-list-item highlight">
            <h5>Einmietung</h5>
            <p class="">
              <span class="text-tiny-section">
                Die Einmietung in die Praxis&shy;gemeinschaft ist ab einem Vier-Stunden-Block möglich.
              </span>
              <span class="text-tiny-section">
                Block Vormittag<br>
                8:00 — 12:00 Uhr
              </span>
              <span class="text-tiny-section">
                Block Nachmittag<br>
                12:30 — 16:30 Uhr
              </span>
              <span class="text-tiny-section">
                Block Abend<br>
                17:00 — 21:00 Uhr
              </span>
            </p>
          </div>

          <div class="information-list-item highlight">
            <h5>Preis</h5>
            <p>
              Auf Anfrage. Kontaktieren Sie uns gerne.
            </p>
          </div>
        </div>
      </div>

      <div class="section section-join-cta">
        <div class="">
          <h2 class="headline-ambient">Werden Sie Teil unseres Kollektivs</h2>
          <p class="copytext-ambient">
            Kontaktieren Sie mich gerne für ein Gespräch oder einen Besichtigungstermin in der Praxis.
          </p>
        </div>

        <div class="button-holder">
          <a  href="tel:+4369917087950"
              id="button-phone"
              class="button-paper">
              <span class="button-text">+43 699 170 87 950</span>
              <img  :src="this.paperButton01"
                    alt="Button Texture"
                    class="button-texture">
          </a>

          <a  href="mailto:a.softic@praxiskollektivwollzeile.at"
              id="button-mail"
              class="button-paper">
              <span class="button-text">a.softic@praxiskollektivwollzeile.at</span>
              <img  :src="this.paperButton02"
                    alt="Button Texture"
                    class="button-texture">
          </a>
        </div>
      </div>

      <div class="section section-join-sender">
        <p class="copytext-ambient">
          <!-- <span class="adress">Mit besten Grüßen,</span> -->
          <span style="margin-right: -20px;">Herzlich,</span><br>
          Amila Softić
        </p>
        <p class="copytext">
          —<br>
          Gestalterin und Initiatorin der Praxisgemeinschaft in der Wollzeile
        </p>
      </div>

    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {
      paperButton01: require('@/assets/images/pkw-ui-paper-button-01.png'),
      paperButton02: require('@/assets/images/pkw-ui-paper-button-02.png')
    }
  },
  metaInfo: {
    title: 'Treten Sie unserer Praxisgemeinschaft bei | PRAXIS KOLLEKTIV WOLLZEILE',
    meta: [
      {name: 'description', content: `Mieten Sie einen kunstvollen und ästhetischen Praxisraum mitten in der Wiener Innenstadt um sich sorgenfrei Ihren Patienten widmen zu können.`},
      // Schema.org markup for Google+
      {itemprop: 'description', content: `Mieten Sie einen kunstvollen und ästhetischen Praxisraum mitten in der Wiener Innenstadt um sich sorgenfrei Ihren Patienten widmen zu können.`},
      {name: 'robots', content: `index, follow`}
    ],
  },
}
</script>

<style lang="scss" scoped>
  @import '../assets/css/typography.scss';

  .section.section-join-letter {
    padding: 0;
  }

  .headline-keyword {
    margin-bottom: calc(var(--font-size-p) * 1.25);
    color: var(--color-stone-03);
  }

  .adress {
    display: block;
    margin-bottom: var(--spacer-responsive-03);
  }

  .section.section-join-details {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    // padding-top: calc(var(--spacer-responsive-top-05)*.5);

    .information-list {
      width: 100%;
      display: grid;
      grid-column-gap: var(--spacer-responsive-03);
      grid-row-gap: calc(var(--spacer-responsive-03) * 2);

      @media screen and (min-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
        // grid-template-rows: repeat(3, auto);
      }

      @media screen and (min-width: 1000px) {
        grid-template-columns: repeat(4, 1fr);
        // grid-template-rows: repeat(3, auto);
      }

      @media screen and (min-width: 1400px) {
        grid-template-columns: repeat(5, 1fr);
        // grid-template-rows: repeat(3, auto);
      }
      
      .information-list-item {
        color: var(--color-stone-03);

        &.highlight {
          color: var(--color-ink-05);
        }
        // min-width: 200px;

        p {
          @extend .copytext;
          text-align: left;
          margin-bottom: 0;
        }
      }
    }
  }

  .section.section-join-cta {
    padding: 0;
    // padding-top: calc(var(--spacer-responsive-top-05)*.6);

    .copytext-ambient {
      font-weight: 100;
      text-align: center;
      hyphens: none;
      max-width: 100vmin;
    }

    .button-holder {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .button-paper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(var(--spacer-responsive-top-05) * .5);
        max-width: 100%;

        @media screen and (min-width: 1000px) {
          &:not(:last-of-type) {
            margin-right: var(--spacer-responsive-03);            
          }
        }

        .button-text {
          position: absolute;
          z-index: 10;
          display: inline-block;
          margin: 0 auto;
          color: inherit;
          text-decoration: none;
          text-align: center;
          opacity: .75;
          color: var(--color-ink-05);
          font-size: calc(var(--font-size-p) * 1.25);
        }

        .button-texture {
          height: 100%;
          width: 100%;

          @media screen and (min-width: 1000px) {
            width: auto;
          }
        }
      }
    }
  }

  .section.section-join-sender {
    // padding-top: calc(var(--spacer-responsive-top-05)*.5);
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    text-align: right;
    
    .copytext-ambient {
      width: 100%;
      text-align: right;
      margin-bottom: 0;
    }

    .copytext {
      text-align: right;
      max-width: 300px;
      hyphens: none;
      margin-left: auto;
      margin-right: 0;
    }
  }




</style>